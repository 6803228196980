<template>
  <div class="mt-32">
    <h1 class="block text-xl font-medium text-gray-900">{{loc.my_download_zone}}</h1>
    <div class="lg:flex gap-x-4 space-y-4 lg:space-y-0 mt-6">
      <section class="lg:w-1/2 bg-gray-50 rounded-lg shadow-xl p-3 lg:p-6">
        <h2 class="text-xl font-medium  flex items-center gap-x-2">
          <font-awesome-icon  icon="fa-file-lines" />
          {{ loc.files_and_templates }}
        </h2>
        <div>
          <div class="tracking-wide mt-6">
            <span>Team Building Template: </span>
            <a class="text-gray-700 hover:text-base-500 underline" target="_blank" href="/team-building-application-template(2024).xlsx">
              {{loc.download_here}}
            </a>
          </div>
        </div>
      </section>
      <section class="lg:w-1/2  bg-gray-50 rounded-lg shadow-xl p-3 lg:p-6">
        <h2 class="text-xl font-medium flex items-center gap-x-2">
          <font-awesome-icon  icon="fa-file-powerpoint" />
          {{ loc.presentations }}
        </h2>
        <div>
          <div class="tracking-wide mt-6">
            <span>Continental benefits: </span>
            <a class="text-gray-700 hover:text-base-500 underline" target="_blank" href="https://hob.fra1.cdn.digitaloceanspaces.com/Benefits%20for%20employees.mov">
              {{loc.download_here}}
            </a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyDownloadZone",
  data() {
    return {

    }
  }
}
</script>

<style scoped>

</style>